import routes from './routes'
import { route } from 'quasar/wrappers'
import { setSeo } from 'hc-core/composables/seo'
import stelace from 'hc-core/composables/stelace'
import { filter, get, set } from 'lodash'
import { base64Decoder } from 'hc-core/composables/routing'
import { analyticsSlugExtractor } from 'hc-core/composables/logger'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import { useSession } from 'hc-core/composables/useSession'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(async function ({ store, ssrContext }) {
  const session = useSession(ssrContext)

  const pages = await store.dispatch('content/fetchPages')
  for (const page of pages) {
    if (page.metadata && page.metadata.active) {
      const hasRoute = filter(routes[0].children, (r) => r.name === page.name)
      if (hasRoute.length === 0) routes[0].children.push({ path: page.name, name: page.name, meta: { fields: page.fields, staticSEO: true }, component: () => import('pages/Static/Page.vue') })
    }
  }

  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? undefined : process.env.VUE_ROUTER_BASE)
  })

  // Extracted SEO call to allow SEO earlier on server
  Router.beforeResolve(async (to, from, next) => {
    if (to) await setSeo({ store, currentRoute: to, redirect: Router.push })
    if (to.query.redirect) next(base64Decoder(to.query.redirect))
    else next()
  })

  if (process.env.CLIENT) {
    Router.beforeEach(async (to, from, next) => {
      try {
        if (get(from, 'query.embed', false)) set(to, 'query.embed', get(from, 'query.embed', undefined))

        // Ensure cookie are good
        session.sync()
        // if (stelace.auth.info().isAuthenticated && !store.getters['auth/currentUser']) await store.dispatch('auth/fetchCurrentUser')

        if (to.query.redirect) next(base64Decoder(to.query.redirect))
        else next()
      } catch (error) {
        next({ name: 'home' })
      }
    })
    Router.afterEach(async (to) => {
      try {
        await analyticsSlugExtractor({ store, to })
        if (stelace.auth.info().isAuthenticated && !store.getters['auth/currentUser']) await store.dispatch('auth/fetchCurrentUser')
      } catch (e) {}
    })
  }
  return Router
})
